import {RedfactHelper} from "./RedfactHelper";

const DOMAIN_MM = 'mannheimer-morgen.de';
const DOMAIN_BA = 'bergstraesser-anzeiger.de';
const DOMAIN_SZ = 'schwetzinger-zeitung.de';
const DOMAIN_FN = 'fnweb.de';
const PUID_MM = 3;
const PUID_BA = 5;
const PUID_SZ = 6;
const PUID_FN = 7;
const REGEX_HOSTNAME = /https?:\/\/([^\/]*)(.*)/;
const REGEX_HOSTNAME_REDFACT = /https?:\/\/(www|red|prelive|red-prelive|integration|test\d+|xmedias\d+|dev\d+)?.(mannheimer-morgen|bergstraesser-anzeiger|schwetzinger-zeitung|fnweb).de/;
const REGEX_HOSTNAME_WWW2 = /https?:\/\/(authapi-epaper|www2|www2auth|www2tools)?-?(test)?.(mannheimer-morgen|bergstraesser-anzeiger|schwetzinger-zeitung|fnweb).de/;
const REGEX_HOSTNAME_EPAPER = /https?:\/\/e-paper.(mannheimer-morgen|bergstraesser-anzeiger|schwetzinger-zeitung|fnweb).de/;

export class Environment {

  /**
   * constructor to create Environment instance
   * @return {object} Environment instance
   */
  constructor() {
    this.isStaticFractalBuild = this.isStaticFractalBuild.bind(this);
  }

  isLocalhost() {
    return window.location.host.match(/^localhost/) !== null
      || window.location.host.match(/^172/) !== null;
  }

  /**
   * if location pathname identical with static fractal build return true
   * @returns {boolean}
   */
  isStaticFractalBuild () {
    if ( typeof this.staticFractalBuild === 'undefined' ) {
      this.staticFractalBuild =  window.location.pathname.match(/\/pu_mm_2020\/espi-build\//) !== null;
    }
    return this.staticFractalBuild;
  }

  isTestDatabaseEnvironment() {
    return window.location.host.match(/^(xmedias|dev|test|localhost|integration)/) !== null;
  }

  getCurrentPuId () {

    let regEx = /^[^.]*./
    let domain = window.location.hostname;
    let pu = 0;
    switch (domain.replace(regEx, '')) {
      case DOMAIN_MM:
        pu = PUID_MM;
        break;
      case DOMAIN_BA:
       pu = PUID_BA;
       break;
      case DOMAIN_SZ:
        pu = PUID_SZ;
        break;
      case DOMAIN_FN:
        pu = PUID_FN;
       break;
      default:
        pu = PUID_MM;
       break;
    }

    return pu;
  }

  /**
   * Get domain
   * @param {number} puId optionally, if not passed, the current request puId is fetched by getCurrentPuId()
   * @returns {string} e.g. 'mannheimer-morgen.de' or 'bergstraesser-anzeiger.de'
   */
  getDomain ( puId = null ) {

    // if puId not passed, the current request puId is fetched by getCurrentPuId()
    if ( typeof puId !== 'number' ) {
      puId = this.getCurrentPuId();
    }

    let domain;
    switch (puId) {
      case PUID_BA:
       domain = DOMAIN_BA;
       break;
      case PUID_SZ:
        domain = DOMAIN_SZ;
        break;
      case PUID_FN:
        domain = DOMAIN_FN;
       break;
      case PUID_MM:
      default:
        domain = DOMAIN_MM;
       break;
    }

    return domain;
  }

  getRedfactEndpointHostname () {
    return (new RedfactHelper()).getRedfactEndpointHostname();
  }


  isArticleDetailPage () {
    if(window.location.href.includes('arid')) {
      return true;
    } else {
      return false;
    }
  }

  isDevArticleDetailPage () {
    return window.location.href.match(/http:\/\/localhost:3000\/components\/preview\/pages-article/) !== null;
  }


  isPayStandalonePage () {
    // e.g. https://www.mannheimer-morgen.de/payment_action,checkoutflow_code,T19PTEJNRE5PNllCSVlENFQ3V0Q%3D.html
    // e.g. https://test9.mannheimer-morgen.de/index.php?action=checkoutflow&code=T181Vk81QjFDRk9IODZNTE5MNEg%3D&puid=3&pageid=3133
    return window.location.href.match(/action[,=]+checkoutflow/) !== null;
  }

  isMSIE() {
    return !!(window.navigator.userAgent.match(/MSIE/)
      || !!navigator.userAgent.match(/Trident.*rv\:11\./));
  }

  /**
   * Nur redfact, www2 oder e-paper hostnamen sind valide und als redirect-ziele zugelassen
   * @param url string z.B. https://test8.mannheimer-morgen.de/orte/mannheim_puid,3.html
   * @returns {boolean}
   */
  isValidHaasHostname (url) {
    let valid = false;
    if ( url !== undefined
      && url.match(REGEX_HOSTNAME_REDFACT) !== null
      || url.match(REGEX_HOSTNAME_WWW2) !== null
      || url.match(REGEX_HOSTNAME_EPAPER) !== null ) {
      valid = true;
    }
    return valid;
  }

  /**
   * Nur redfact hostnamen sind valide und als redirect-ziele zugelassen
   * @param url string z.B. return false https://authapi-epaper.mannheimer-morgen.de/login.html?redirecturi=https%3A%2F%2Fe-paper.mannheimer-morgen.de%2Ftitles%2F
   *                   z.B. return true https://www.mannheimer-morgen.de/service/epaper.html
   * @returns {boolean}
   */
  isValidRedfactHostname (url) {
    let valid = false;
    if ( url !== undefined
      && url.match(REGEX_HOSTNAME_REDFACT) !== null ) {
      valid = true;
    }
    return valid;
  }
}
